import React from "react"
import { Link } from "gatsby";

import Layout from "../containers/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="fourofour">
      <div className="fourofour__content">
        <h1>404, Oeps!</h1>
        <p>Het lijkt erop dat deze pagina niet bestaat, of recentelijk verwijderd is.</p>
        <Link to="/">
          <button className={'button'}>Terug naar homepage</button>
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
